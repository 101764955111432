import { DecimalPipe } from '@angular/common';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { CrmLocaleServiceToken } from 'common-module/translate';
import { format, isSameDay, isValid } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DateInput,
  getDate,
  TIME_FORMAT,
} from '../utils/date/date-format';

import { CurrentClinicService } from './current-clinic.service';

export interface BaseFormatNumberOptions {
  value?: number;
  digits?: string;
  transformer?: (value: string) => string;
}

export interface FormatNumberOptions extends BaseFormatNumberOptions {
  append?: string;
}

@Injectable({ providedIn: 'root' })
export class FormatterService {
  public static instance: FormatterService;

  private decimal = inject(DecimalPipe);
  private localeService = inject(CrmLocaleServiceToken);
  private currentClinic = inject(CurrentClinicService);

  constructor() {
    FormatterService.instance = this;
  }

  static priceFormatter = (value: number) =>
    FormatterService.instance.formatNumber({ value: +value, digits: '1.2-2' });

  static percentFormatter = (value: number) =>
    FormatterService.instance.formatNumber({ value: +value, digits: '1.0-2' });

  static numberParser = (value: string) =>
    FormatterService.instance.parseNumber(value);

  public formatDateInput(
    value: DateInput | undefined,
    _format: string,
  ): string {
    if (!value) {
      return '-';
    }

    const _value = getDate(value);
    const locales = this.localeService.config.locales;
    const locale = locales?.[this.localeService.currentLocale()]?.dateFnsLocale;
    return _value && isValid(_value)
      ? format(_value, _format, { locale })
      : '-';
  }

  public formatRange(start: DateInput, end: DateInput): string {
    const _start = getDate(start);
    const _end = getDate(end);
    let formatEnd = TIME_FORMAT;
    if (_start && _end && !isSameDay(_start, _end)) {
      formatEnd = DATE_TIME_FORMAT;
    }
    return `${this.formatDateTime(start)} - ${this.formatDateInput(
      end,
      formatEnd,
    )}`;
  }

  public formatDate(value?: DateInput): string {
    const parsed = typeof value === 'string' ? value.substring(0, 10) : value;
    return this.formatDateInput(parsed, DATE_FORMAT);
  }

  public formatTime(value?: DateInput): string {
    return this.formatDateInput(value, TIME_FORMAT);
  }

  public formatDateTime(value?: DateInput): string {
    return this.formatDateInput(value, DATE_TIME_FORMAT);
  }

  public formatNumber(options: FormatNumberOptions): string {
    const {
      value = 0,
      append = '',
      digits = '1.0-2',
      transformer = (val: string) => val,
    } = options ?? {};
    const formatted = this.decimal.transform(value, digits);
    return transformer(`${formatted}${append}`);
  }

  public formatCurrency(options: BaseFormatNumberOptions): string {
    const { digits = '1.2-2' } = options ?? {};
    return this.formatNumber({
      ...options,
      digits,
      append: this.currentClinic.currencySymbol(),
    });
  }

  public computeCurrency(signal: Signal<number | undefined>) {
    return computed(() => this.formatCurrency({ value: signal() }));
  }

  public formatPercent(options: BaseFormatNumberOptions): string {
    return this.formatNumber({ ...options, append: '%' });
  }

  public computePercent(signal: Signal<number | undefined>) {
    return computed(() => this.formatPercent({ value: signal() }));
  }

  public formatPhone(value?: string): string {
    if (!value) {
      return '-';
    }

    try {
      const parsed = parsePhoneNumberFromString(value);
      return parsed?.formatInternational() ?? '';
    } catch {
      return value;
    }
  }

  public parseNumber(value: string): string {
    return value.replace(/,/g, '.');
  }
}
